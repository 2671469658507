
import { getUser, getAllParentBu } from "@/API/claim/delaerClaim/delaer";
import { defineComponent, ref, onMounted, nextTick } from "vue";
import Retail from "./RetailValdationOverview/index2.vue";
import IncentiveClaim from "./IncentiveClaim/index2.vue";
import PreaymentDealerCheck from "./PrepaymentQualifiedDealerCheck/index.vue";
import { findBrandByBu } from "@/API/salesReporting";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    Retail,
    IncentiveClaim,
    PreaymentDealerCheck,
  },
  setup() {
    //
    const isDealer = ref(true);
    const store = useStore();
    if ((store.state.user as any).legalEntity.entityCode !== "Dealer") {
      isDealer.value = false;
    }

    const dom1 = ref<any>(null);
    const dom2 = ref<any>(null);
    const buArray = ref<any[]>([]);
    const buIDArray = ref<string[]>([]);
    const brandArray = ref<any[]>([]);
    const getBU = async () => {
      return getAllParentBu();
    };
    const getBrand = async () => {
      return findBrandByBu({ buIds: buIDArray.value });
    };

    const isShow = ref(false);

    const init = async () => {
      //
      const buList = await getBU();
      console.log(buList);
      const bus = buList.filter((item: any) => {
        // if (item.parentId === null){
        return item.nameEn !== "Companycar";
        // }
      });
      bus.filter((item: any) => {
        buIDArray.value.push(item.id);
        buArray.value.push(item);
      });
      for (let i = 0; i < buArray.value.length; i++) {
        if (
          buArray.value[i].nameEn !== "CPO" &&
          buArray.value[i].nameEn !== "Companycar"
        ) {
          isShow.value = true;
        }
      }
      const brandList = await getBrand();
      nextTick(() => {
        if (isShow.value) {
          dom1.value.init(buArray.value, brandList);
        }

        dom2.value.init(buArray.value, brandList);
      });
    };

    onMounted(() => {
      init();
    });
    return {
      buIDArray,
      brandArray,
      dom2,
      dom1,
      isDealer,
      isShow,
    };
  },
});
