
import {
  queryHistogram,
  getTDTA2,
  getRVOverview,
  getQuarter,
} from "@/API/salesReporting";
import { getDealerByGroupId } from "@/API/salesReporting";
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  toRefs,
  nextTick,
} from "vue";
import * as echarts from "echarts";
import moment from "moment";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    let total: any[] = [];
    const TDTAText = ref("CBU + PBP");
    const { state } = useStore();
    const store = useStore();
    const isDealer = ref(true);
    if ((store.state.user as any).legalEntity.entityCode !== "Dealer") {
      isDealer.value = false;
    }
    const dealerCode = ref<null | string>(null);
    if (isDealer.value) {
      dealerCode.value = (state.user as any).organization.entityCode;
    }

    // const dealerCode = ref('193120340');
    const router = useRouter();
    const myChart = ref<any>(null);
    const myChartDom = ref<any>(null);
    const buList = ref<any[]>([]);
    const brandList = ref<any[]>([]);
    const brandArray = ref<any[]>([]);
    interface SearchData {
      bu: string | null;
      brand: string | null;
      year: string | null;
      quarter: string | null;
    }
    const searchData: SearchData = reactive({
      bu: "",
      brand: "",
      year: "",
      quarter: "1",
    });

    const RVIndex = ref<any>({
      currDiffer: 0,
      currMonth: "",
      currRetailOverDate: "",
      preDiffer: 0,
      preMonth: "",
      preRetailOverDate: "",
    });

    const yearArray: Array<string> = [];
    for (let i = 5; i >= 0; i--) {
      yearArray.push(moment().subtract(i, "year").format("YYYY"));
    }
    for (let i = 1; i <= 5; i++) {
      yearArray.push(moment().add(i, "year").format("YYYY"));
    }

    const option = ref<any>({
      grid: {
        left: "0%",
        right: "0%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
        axisTick: {
          show: false,
        },
      },
      yAxis: [
        {
          type: "value",
          show: false,
        },
        {
          type: "value",
          show: false,
          min: 0,
          max: 100,
        },
      ],
      legend: {
        x: "left",
      },
      tooltip: {
        trigger: "axis",
      },
      label: {
        show: true,
        position: "top",
      },
      series: [
        {
          name: "非有效零售数量",
          data: [],
          type: "bar",
          zlevel: 1,
          barWidth: 20,
          color: "#00ADEF",
          label: {
            color: "#0088C6",
          },
        },
        {
          name: "有效零售数量",
          data: [],
          type: "bar",
          barGap: "-100%",
          barWidth: 20,
          color: "#7FD6F7",
          label: {
            color: "#7FD6F7",
            // formatter: (params: any) => {
            //     return total[params.dataIndex];
            // },
          },
        },
        {
          name: "零售校验通过率",
          data: [],
          type: "line",
          yAxisIndex: 1,
          color: "#FFBA3E",
          label: {
            color: "#FFBA3E",
            formatter: "{c}%",
          },
        },
      ],
    });

    const dataSource = ref<any>([]);

    const buName = ref("");

    const columns = ref<any[]>([]);

    const numVal = ref("");
    const percentageComplete = ref("");
    const queryTDTA = (array: Array<string>) => {
      getTDTA2({
        year: searchData.year as string,
        quarter: "Q" + parseInt(searchData.quarter as string),
        dealerCodes: array,
        bu: buName.value,
        brand: searchData.brand,
      }).then((res) => {
        if (res.numVal !== null && res.numVal !== "N/A") {
          numVal.value = res.numVal;
        } else {
          numVal.value = "-";
        }
        if (
          res.percentageComplete !== null &&
          res.percentageComplete !== "N/A"
        ) {
          percentageComplete.value = res.percentageComplete;
        } else {
          percentageComplete.value = "-";
        }
      });
    };
    let first = true;
    const queryData = async () => {
      if (searchData.bu && searchData.year && searchData.quarter) {
        for (let i = 0; i < buList.value.length; i++) {
          if (buList.value[i].id === searchData.bu) {
            buName.value = buList.value[i].nameEn;
          }
        }

        let brand = null;
        if (searchData.brand !== "All") {
          brand = searchData.brand;
        }

        const dealerCodeArray: Array<string> = [];
        if (isDealer.value) {
          dealerCodeArray.push(dealerCode.value as string);
        } else {
          await getDealerByGroupId((store.state.user as any).orgid).then(
            (res) => {
              for (let i = 0; i < res.length; i++) {
                dealerCodeArray.push(res[i].dealerInfo.dealerCode);
              }
            }
          );
        }

        console.log("brand", searchData.brand);

        if (buName.value == "VAN") {
          TDTAText.value = "VAN";
        } else if (buName.value == "DENZA") {
          TDTAText.value = "DENZA";
        } else {
          TDTAText.value = "CBU + PBP";
        }
        if (
          searchData.brand == "All" ||
          buName.value == "Fleet" ||
          searchData.brand === undefined
        ) {
          numVal.value = "-";
          percentageComplete.value = "-";
        } else {
          queryTDTA(dealerCodeArray);
        }

        queryHistogram({
          bu: buName.value,
          buId: searchData.bu,
          brand: brand,
          year: searchData.year,
          quarter: searchData.quarter,
          dealerCodes: isDealer.value ? null : dealerCodeArray,
          dealerCode: isDealer.value ? dealerCode.value : null,
        }).then((res) => {
          const totalArray = [];
          const totalArray2 = [];
          const retailRateArray = [];
          total = [];
          for (let i = 0; i < res.length; i++) {
            total.push(res[i].total);
            totalArray.push(res[i].eligibleRetailNo);
            totalArray2.push(res[i].ineligible);
            retailRateArray.push(
              res[i].retailRate === "N/A"
                ? "N/A"
                : res[i].retailRate.replace("%", "")
            );
          }
          option.value.series[1].data = totalArray;
          option.value.series[2].data = retailRateArray; //lv
          option.value.series[0].data = totalArray2; //非
          myChart.value.setOption(option.value);
          if (first) {
            myChart.value.setOption(option.value);
            first = false;
          }
        });
        getRVOverview().then((res) => {
          RVIndex.value = res;
        });
        getQuarter({
          bu: buName.value,
          buId: searchData.bu,
          brand: brand,
          year: searchData.year,
          quarter: searchData.quarter,
          dealerCodes: isDealer.value ? null : dealerCodeArray,
          dealerCode: isDealer.value ? dealerCode.value : null,
        }).then((res) => {
          // console.log(res)
          columns.value = [
            {
              title: "零售校验",
              dataIndex: "name",
              key: "name",
            },
          ];
          dataSource.value = [
            {
              name: "通过率",
              value0: "",
              value1: "",
              value2: "",
            },
            {
              name: "提交率",
              value0: "",
              value1: "",
              value2: "",
            },
          ];
          for (let i = 0; i < res.length; i++) {
            columns.value.push({
              title: `${res[i].month}月`,
              dataIndex: `value${i}`,
              key: `value${i}`,
            });
            dataSource.value[0][`value${i}`] =
              res[i].retailCheckRate == "N/A" ? "-" : res[i].retailCheckRate;
            dataSource.value[1][`value${i}`] =
              res[i].totalSubmitRate == "N/A" ? "-" : res[i].totalSubmitRate;
          }
        });
      } else {
        message.error("请选择查询项");
      }
    };
    const isShow = ref(true);
    const isRegion = store.state.user.organization.entityCode === "Region";
    const init = (buArray: Array<any>, brandArrayData: Array<any>) => {
      let bus = buArray;
      console.log(bus);
      if (!isRegion) {
        bus = bus.filter((bu: any) => {
          return bu.parentId === null;
        });
      }
      //buList.value = bus;
      buList.value = bus.filter((bu: any) => {
        return bu.nameEn !== "Companycar" && bu.nameEn !== "CPO";
      });
      // for (let i = 0; i < buArray.length; i++) {
      //   if (buArray[i].nameEn !== "CPO" && buArray[i].nameEn !== "Companycar") {
      //     buList.value.push(buArray[i]);
      //   }
      // }
      if (buList.value.length === 0) {
        isShow.value = false;
        return;
      }
      brandArray.value = brandArrayData;
      searchData.bu = buList.value[0].id;
      for (let i = 0; i < buArray.length; i++) {
        if ((buArray[i] as any).nameEn === "MBPC") {
          searchData.bu = (buArray[i] as any).id;
        }
      }
      // if (brandArray.length){
      //     searchData.brand = brandArray[0].brandNameEn
      // }
      let array: Array<any> = [];
      for (let i = 0; i < brandArray.value.length; i++) {
        if (searchData.bu === brandArray.value[i].buId) {
          array = JSON.parse(JSON.stringify(brandArray.value[i].brandList));
        }
      }

      for (let i = 0; i < array.length; i++) {
        if (array[i].brandNameEn !== "DENZA") {
          brandList.value.push(array[i]);
        }
      }

      if (brandList.value.length) {
        brandList.value.push({
          brandNameCn: "All",
          brandNameEn: "All",
          id: -1,
        });
        searchData.brand = "All";
      }

      searchData.year = moment().format("YYYY");
      searchData.quarter = moment().quarter() + "";
      // myChart.value = echarts.init(myChart.value);
      myChart.value = echarts.init(myChartDom.value);
      queryData();
    };

    onMounted(() => {
      //aaa
      // myChart.value = echarts.init(myChart.value);
      // myChart.value.setOption(option.value)
      // window.onresize = function () {
      //     // 自适应大小
      //     myChart.value.resize();
      // };
    });

    const gotoDetail = () => {
      let name = "";
      for (let i = 0; i < buList.value.length; i++) {
        if (buList.value[i].id == searchData.bu) {
          name = buList.value[i].nameEn;
        }
      }

      let brand = "";
      if (searchData.brand) {
        brand = searchData.brand as string;
      }
      router.push(
        `/salesreporting/RVSubmissionDealer?bu=${name}&brand=${brand}&year=${searchData.year}&quarter=${searchData.quarter}`
      );
    };

    const getBrandList = (value: number) => {
      // console.log(value)
      searchData.brand = null;
      brandList.value = [];
      let array: Array<any> = [];
      for (let i = 0; i < brandArray.value.length; i++) {
        if (value === brandArray.value[i].buId) {
          array = JSON.parse(JSON.stringify(brandArray.value[i].brandList));
        }
      }

      for (let i = 0; i < array.length; i++) {
        if (array[i].brandNameEn !== "DENZA") {
          brandList.value.push(array[i]);
        }
      }
      if (brandList.value.length) {
        // searchData.brand = brandList.value[0].brandNameEn
        brandList.value.push({
          brandNameCn: "All",
          brandNameEn: "All",
          id: -1,
        });
      }
      searchData.brand = "All";
    };

    //period初始化
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format("YYYY");
      searchData.year = time as string;
      openProgramPeriodYear.value = false;
    };

    return {
      isShow,
      openProgramPeriodYear,
      handlerPanelProgramPeriodChange,
      handlerOpenProgramPeriodChange,
      RVIndex,
      myChartDom,
      queryData,
      gotoDetail,
      numVal,
      percentageComplete,
      getBrandList,
      myChart,
      dataSource,
      columns,
      buList,
      brandList,
      yearArray,
      ...toRefs(searchData),
      init,
      TDTAText,
    };
  },
});
