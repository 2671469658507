import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59dc9ea3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sale-overview-retail",
  style: {"margin-top":"5px"}
}
const _hoisted_2 = { class: "sale-overview-retail" }
const _hoisted_3 = {
  key: 1,
  class: "sale-overview-retail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Retail = _resolveComponent("Retail")!
  const _component_IncentiveClaim = _resolveComponent("IncentiveClaim")!
  const _component_PreaymentDealerCheck = _resolveComponent("PreaymentDealerCheck")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumb, { dataSource: [] }),
    (_ctx.isShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Retail, { ref: "dom1" }, null, 512)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IncentiveClaim, { ref: "dom2" }, null, 512)
    ]),
    (_ctx.isDealer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_PreaymentDealerCheck)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}